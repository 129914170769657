// import { appRoutes } from '../constants';
import { useSelector } from 'react-redux';
import Routes from 'src/routes';
import { StoreConst, store } from '../src/store/local';
// import { useAuthContext } from '../contexts';
// import { store, StoreConst } from 'src/store/local';

//check if you are on the client (browser) or server
const isBrowser = () => typeof window !== 'undefined';

const ProtectedRoute = ({ router, children }) => {
  //Identify authenticated user
  //   const { user } = useAuthContext();
  const user = useSelector((state) => state.auth.authData);
  // const user = store.get(StoreConst.USER);
  //   console.log(user)
  //   const isAuthenticated = user.isLoggedIn;

  let unprotectedRoutes = [
    //    Routes.
    Routes.homePage,
    Routes.gigs,
    Routes.loginCover,
    Routes.resetPassword,
    Routes.registerCover,
  ];

  /**
   * var pathIsProtected Checks if path exists in the unprotectedRoutes routes array
   */
  // debugger
  // console.log("code"+ Routes.gigs)
  console.log(router.pathname);
  let pathIsProtected = unprotectedRoutes.indexOf(router.pathname) === -1;
  // console.log('path Is Protected ' + pathIsProtected);

  if (isBrowser() && !user && pathIsProtected) {
    router.push(Routes.loginCover);
  }

  return children;
};

export default ProtectedRoute;
