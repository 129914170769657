// scroll bar
import 'simplebar/src/simplebar.css';
import '../style.css';

// lightbox
import 'react-image-lightbox/style.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

// ----------------------------------------------------------------------

import PropTypes from 'prop-types';
// next
import Head from 'next/head';
// @mui
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// contexts
import { SettingsProvider } from '../src/contexts/SettingsContext';
// theme
import ThemeProvider from '../src/theme';
// utils
import axios from '../src/utils/axios';
// components
import Settings from '../src/components/settings';
import RtlLayout from '../src/components/RtlLayout';
import ProgressBar from '../src/components/ProgressBar';
import ThemeColorPresets from '../src/components/ThemeColorPresets';
import MotionLazyContainer from '../src/components/animate/MotionLazyContainer';

import { Provider, useSelector } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

// ----------------------------------------------------------------------

//Store
import store from '../src/store/index';
import ProtectedRoute from './ProtectedRoute';
import { useRouter } from 'next/router';
import { BASE_URL } from 'src/config';
// import { store as localstore, StoreConst } from 'src/store/local';

let persistor = persistStore(store);

export default function MyApp(props) {
  const router = useRouter();
  const { Component, pageProps } = props;

  const getLayout = Component.getLayout ?? ((page) => page);

  console.info('[INFO] baseAPI: ', BASE_URL);
  console.info('[INFO] env: ', process.env.API_URL);
  console.info('[INFO] NODE_ENV: ', process.env.NODE_ENV); 
  
  console.info('[INFO] NEXT_PUBLIC_APP_ENV: ', process.env.NEXT_PUBLIC_APP_ENV);

  let user;
  if (typeof window !== 'undefined') {
    // Perform localStorage action
    // user = localstore.get(StoreConst.USER);
    // console.log('😈😈😈', user);
    // console.log(router.pathname);
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ProtectedRoute router={router}>
          <Head>
            <meta name="viewport" content="initial-scale=1, width=device-width" />
            {/* <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests" /> */}
          </Head>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SettingsProvider>
              <ThemeProvider>
                <ThemeColorPresets>
                  <MotionLazyContainer>
                    <RtlLayout>
                      <Settings />
                      <ProgressBar />
                      {getLayout(<Component {...pageProps} />)}
                    </RtlLayout>
                  </MotionLazyContainer>
                </ThemeColorPresets>
              </ThemeProvider>
            </SettingsProvider>
          </LocalizationProvider>
        </ProtectedRoute>
      </PersistGate>
    </Provider>
  );
}
